<template>
  <div>
    <template v-if="isErrored">
      <v-icon color="error" small left> mdi-alert-circle </v-icon>
      <small> Falha ao enviar mensagem </small>
    </template>
    <template v-else>
      <template v-if="!session.status_by_customer">
        <v-icon color="#00A884" small class="mr-1">mdi-whatsapp</v-icon>
        <small style="color: #00a884">
          {{ statuses[notificationStatus].text }}
        </small>
        <v-icon class="ml-2" v-if="statuses[notificationStatus].statusIcon"
          :color="statuses[notificationStatus].statusIconColor" small>
          {{ statuses[notificationStatus].statusIcon }}
        </v-icon>
      </template>
      <template v-if="session.status_by_customer">
        <template v-if="session.status_by_customer == 'confirmed'">
          <v-icon color="#00A884" small class="mr-1">mdi-whatsapp</v-icon>
          <small style="color: #00a884">
            Sessão confirmada pelo {{ customerAlias }}
          </small>
          <v-icon class="ml-2" :color="'info'" small>
            mdi-thumb-up-outline
          </v-icon>
        </template>
        <template v-if="session.status_by_customer == 'reschedule'">
          <v-icon color="warning" small class="mr-1">mdi-alert-circle</v-icon>
          <small style="color: #00a884">
            {{ customerAlias }} solicitou remarcação!
          </small>
          <!-- <br />
          <small> Motivo: {{ session.customer_justification }} </small> -->
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { customerAlias } from "@/utils/customerAlias";

export default {
  props: {
    session: {},
  },

  data: () => ({
    statuses: {
      willNotify: {
        text: "A mensagem será enviada 1 dia antes da sessão",
      },
      error: {
        text: "Erro ao enviar mensagem",
        statusIcon: "mdi-alert-circle",
        statusIconColor: "error",
      },
      sending: {
        text: "Enviando mensagem de lembrete da sessão",
        statusIcon: "mdi-clock-outline",
        statusIconColor: "gray",
      },
      pending: {
        text: "Enviando mensagem de lembrete da sessão",
        statusIcon: "mdi-clock-outline",
        statusIconColor: "gray",
      },
      success: {
        text: "Mensagem de lembrete da sessão foi enviada",
        statusIcon: "mdi-check",
        statusIconColor: "gray",
      },
      RECEIVED: {
        text: "Mensagem de lembrete da sessão recebida",
        statusIcon: "mdi-check-all",
        statusIconColor: "gray",
      },
      READ: {
        text: "Mensagem de lembrete da sessão lida",
        statusIcon: "mdi-check-all",
        statusIconColor: "info",
      },
      SENT: {
        text: "Mensagem de lembrete da sessão enviada",
        statusIcon: "mdi-check-all",
        statusIconColor: "info",
      },
    },
  }),

  computed: {
    customerAlias() {
      return customerAlias();
    },

    notification() {
      return this.session.last_whats_app_notification;
    },

    isNotified() {
      return this.notification != null && this.session.notified_at != null;
    },

    notificationStatus() {
      if (!this.isNotified) {
        return "willNotify";
      }

      if (this.notification.whatsapp_status) {
        return this.notification.whatsapp_status;
      }

      return this.notification.status;
    },

    hasWhatsappId() {
      return this.notification.whatsapp_id != null;
    },

    isErrored() {
      return this.isNotified && !this.hasWhatsappId;
    },
  },
};
</script>

<style></style>
